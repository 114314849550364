import React from "react";

export default function Section1() {
  return (
    <div>
      <div className="px-4 py-5 my-5  cbg5">
        <h1 className="display-5 fw-bold text-body-emphasis txt-1 text-center">
          FREE ONLINE MEDITATION
        </h1>
        <div className="col-lg-10 mx-auto center ">
          <p className="lead mb-4 text-center">
            Meditation is a practice where individuals train their minds to
            achieve a state of focused attention and awareness. It involves
            various techniques such as mindfulness, concentration, or
            contemplation, aimed at calming the mind, reducing stress, and
            promoting relaxation and inner peace.
          </p>
          <hr className="mx-4" />
          <p className="fw-bold fs-3 text-center">We Offer</p>

         <p className="lead fw-bold">Seven Chakkra Meditation, Manifestation Meditation, New Moon Meditation and Full Moon Meditation</p>

          <hr className="mx-4 " />
          <p className="text-center lead">
            Enroll yourself Today, to attend the free online Meditation Session.
            Write "Your Name" [space] "MEDITAION", and send it on WhatsApp. The
            date and time will be shared with you on your phone number.
          </p>

          <a
            className="bi bi-whatsapp btn btn-primary btn-lg"
            href="https://wa.me/+918850758136"
          >
            &nbsp;Register for Meditation
          </a>
        </div>
      </div>
    </div>
  );
}
