import React from "react";
import { useNavigate } from "react-router-dom";


export default function TrDes() {
  const amt = 1500;
const session = "Angel Healing"

const nata = useNavigate()
  function clickk (){
    nata("/pay", {state : {amt : amt, session:session}})
  }
  return (
    <div className="container">
      <div className=" w-100  d-md-flex flex-md-row  my-3 ">
        <div className="col-md-6 px-2 py-2 cbg5">
          <img src="/Images/ang (4).jpeg" alt="Image" className="sec3-img1 "></img>
        </div>
        <div className="col-md-6 px-2 py-2  cbg5">
          <h1 className="display-5 fw-bold text-body-emphasis txt-1 text-center ">
            Angel Healing
          </h1>
          <p className="lead mb-4 ">
            Ready to experience the healing and guidance of the angels? Book
            your angel healing session today and embrace the divine support
            available to you.
          </p>
          <p className="lead mb-4 fw-bold d-md-flex  ">
            Only in:
            <i class="bi bi-currency-rupee fw-light">
              <s>2999</s>
            </i>
            &nbsp; &nbsp;
            <p class="bi bi-currency-rupee fs-5 ">1500</p>
          </p>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">Session Duration:</p>{" "}
            <p className="lead d-inline">1 Hour </p>
          </div>
          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">
              Total number of sessoions:
            </p>{" "}
            <p className="lead d-inline">Depends on the Conditions </p>
          </div>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">Session Mode:</p>{" "}
            <p className="lead d-inline">Online / Offline </p>
          </div>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline "> Category:</p>{" "}
            <p className="lead d-inline"> Healing</p>
          </div>

          <a className="btn btn-primary my-3 "  onClick={clickk} >Book an Appointment</a>
        </div>
      </div>

      <div className=" w-100  d-md-flex flex-md-column  my-3 cbg5">
        <h3 className=" fw-bold text-body-emphasis txt-1 center-c py-3 ">
          Experience Divine Healing and Guidance
        </h3>
        <div className="lead my-4 px-5">
          <p>
            Welcome to our Angel Healing Section page! Connect with the loving
            and healing energies of the angels to bring peace, clarity, and
            balance into your life. Our angel healing sessions offer a deeply
            spiritual and transformative experience, helping you align with your
            highest good.
          </p>
          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">What to Expect</li>
            <p>
              During your angel healing session, our experienced healer will
              facilitate a connection with the angelic realm to channel healing
              energy and messages. This gentle and nurturing process can help
              clear blockages, release negative energy, and restore harmony to
              your mind, body, and spirit.
            </p>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Types of Healings</li>
            <p>
              We offer a variety of Angel Healing options to suit your needs:
            </p>
            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">General Healing:</p>
                <p>
                  {" "}
                  &nbsp; Receive overall healing and balancing for your
                  physical, emotional, and spiritual well-being.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Emotional Healing:</p>
                <p>
                  {" "}
                  &nbsp; Address specific emotional issues and receive comfort
                  and support from the angels.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Chakra Balancing:</p>
                <p>
                  {" "}
                  &nbsp; Clear and balance your chakras to enhance your energy
                  flow and vitality.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Spiritual Guidance:</p>
                <p>
                  {" "}
                  &nbsp; Connect with your guardian angels and receive messages
                  and guidance for your spiritual journey.
                </p>
              </li>
            </ul>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Booking Your Appointment</li>
            <p>
              We offer a variety of Angel Healing options to suit your needs:
            </p>
            <ol class="list-group list-group-numbered">
              <li className="  list-group-item">
                <p className="fw-semibold d-inline">
                  Click on Appointment Button:
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Fill the Details and complete the payment process.
                </p>
              </li>

              {/* <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Fill details and make payment:</p>
                <p>
                  {" "}
                  &nbsp; Explore the dynamics of your relationships and gain
                  clarity on matters of the heart.
                </p>
              </li> */}

              <li className="list-group-item ">
                <p className="fw-semibold d-inline  ">
                  Get a call on your number:{" "}
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Our Team will call on your number to get your available
                  date, time and session type to schedule the session. Also you
                  can share any specific questions or topics you'd like to
                  explore during your session.
                </p>
              </li>

              <li className="  list-group-item">
                <p className="fw-semibold d-inline">Attend the Session:</p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Once all done, on the scheduled date & time join the
                  online session or visit our office (depending on the session
                  type).
                </p>
              </li>
            </ol>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">FAQs</li>
            <p className="fw-semibold">How long does a healing session last?</p>
            <p>
              {" "}
              &nbsp; Our sessions typically last between 45 to 60 minutes,
              depending on the type of reading.
            </p>

            <p className="fw-semibold">
              Do I need to prepare anything for my session?
            </p>
            <p>
              {" "}
              &nbsp; No preparation is needed, but you may want to think about
              any specific areas or issues you'd like to focus on during your
              session.
            </p>

            <p className="fw-semibold">
              Can I receive messages from my guardian angels?
            </p>
            <p>
              {" "}
              &nbsp; Yes, during the session, our healer will facilitate
              communication with your guardian angels, and you may receive
              messages and guidance.
            </p>
          </ul>

          <a className="btn btn-primary my-3 "  onClick={clickk} >Book an Appointment</a>
        </div>
      </div>
    </div>
  );
}
