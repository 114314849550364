import React from "react";
export default function Section3() {
  return (
    <div>
      <div className="sec3">
        <hr className="featurette-divider" />
        <h1 className="display-5 fw-bold text-body-emphasis text-center mar-b-30 txt-2 ">
          OUR SERVICES
        </h1>

        <div className="row featurette mx-5">
          <div className="col-md-7 ">
            <h2 className="featurette-heading fw-normal text-decoration-underline lh-1">
              Tarot Reading
              {/* <span className="text-body-secondary">It’ll blow your mind.</span> */}
            </h2>
            <p className="lead">
              Tarot reading is a practice where a deck of cards, typically with
              symbolic imagery, is used to gain insight into the past, present,
              or future. The reader interprets the cards' meanings and their
              arrangement in a spread to provide guidance, reflection, or advice
              on various aspects of life such as love, career, or personal
              growth. It's often seen as a tool for self-reflection and
              introspection, although interpretations can vary widely depending
              on the reader's beliefs and the specific deck used.
            </p>
            <a className="btn btn-secondary my-3" href="/TrDes">Book Appointment</a>

          </div>
          <div className="col-md-5">
            <img src="/Images/7.jpg" className="sec3-img1"></img>
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7 order-md-2  ">
            <h2 className="featurette-heading fw-normal  text-decoration-underline lh-1">
              Numerology Reading
              {/* <span className="text-body-secondary">See for yourself.</span> */}
            </h2>
            <p className="lead">
              Numerology is a practice that assigns mystical significance to
              numbers and their associations with various aspects of human life.
              A numerology reading involves analyzing numbers derived from a
              person's name or birthdate to uncover insights into their
              personality, strengths, weaknesses, opportunities, and challenges.
            </p>
            <a className="btn btn-secondary my-3" href="/NumeDes">Book Appointment</a>
          </div>
          <div className="col-md-5 order-md-1">
            <img src="/Images/20.jpg" className="sec3-img1"></img>
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal text-decoration-underline lh-1">
              Angel Healing
              {/* <span className="text-body-secondary">Checkmate.</span> */}
            </h2>
            <p className="lead">
              Angel healing is a spiritual practice that involves working with
              angels to facilitate healing and balance on physical, emotional,
              mental, and spiritual levels. It is based on the belief that
              angels are benevolent spiritual beings who can provide guidance,
              protection, and healing energy to those who call upon them.
              <br></br>
              Overall, angel healing is viewed as a complementary spiritual
              practice that supports holistic well-being by tapping into the
              healing power and guidance of angels.
            </p>
            <a className="btn btn-secondary my-3" href="/AngDes">Book Appointment</a>
            
          </div>
          <div className="col-md-5">
            <img src="/Images/13.jpg" className="sec3-img1"></img>
          </div>
        </div>

        <hr className="featurette-divider" />
      </div>
    </div>
  );
}
