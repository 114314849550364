import React, { useState } from "react";
import Sample from "./Sample";
import Texthead1 from "./Texthead1";
import Banner from "./Banner";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Texthead2 from "./Texthead2";
import Section5 from "./Section5";
import ConMod1 from "./ConMod1";
import Image from "./Image";



export default function Main() {
  const hchange = (event) => {
    console.log("on change");
    setTt(event.target.value);
  };

  const [text, setTt] = useState("Enter text here");
  return (
    <>
    <Banner></Banner>
      <Texthead1></Texthead1>
      <Section3></Section3>
      <Texthead2></Texthead2>
      <Section5></Section5>
      <ConMod1></ConMod1>
      
     
     
    </>
  );
}
