import React from 'react'

export default function Pp() {
  return (
    <div className='container'>
       <div className="px-4 py-5 my-5  cbg ">
        
        
        <h1 className="display-5 fw-bold text-body-emphasis txt-1 text-center ">Privacy Policy</h1>
        <div className="col-lg-10 mx-auto">
          <p className="lead mb-4 text-center ">
          We are committed to protecting the privacy and confidentiality of our clients' personal information. This Privacy Policy outlines how we collect, use, and safeguard the information obtained through our Numerology, Tarot or any services.
          </p>
          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Information Collection</li>
            <ul>
            <li className=" lead  ">We collect personal information provided by clients during consultations, such as name, contact details, birth date, and any additional information relevant to the service being provided.</li>
            <li className=" lead  ">Information may also be collected through website forms, emails, or other communication channels.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Use of Information</li>
            <ul>
            <li className=" lead  ">Personal information is used solely for the purpose of providing Numerology, Tarot, or Vastu services and related communications.</li>
            <li className=" lead  ">We may use aggregated and anonymized data for research, analysis, and improvement of our services.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Data Security</li>
            <ul>
            <li className=" lead  ">We implement appropriate technical and organizational measures to protect client information against unauthorized access, alteration, disclosure, or destruction.</li>
            <li className=" lead  ">Access to personal information is restricted to authorized personnel only, who are bound by confidentiality obligations.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Data Retention</li>
            <ul>
            <li className=" lead  ">We retain personal information only for as long as necessary to fulfill the purposes for which it was collected, unless otherwise required by law.</li>
            <li className=" lead  ">Clients may request the deletion of their personal information at any time, subject to legal and contractual obligations.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Third-Party Disclosure</li>
            <ul>
            <li className=" lead  ">We do not sell, trade, or transfer personal information to third parties without explicit consent, except as required by law or to facilitate the provision of services (e.g., sharing birth details with a Numerologist).</li>
            {/* <li className=" lead  ">Use of Information</li> */}
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Client Rights</li>
            <ul>
            <li className=" lead  ">Clients have the right to access, update, or correct their personal information upon request.</li>
            <li className=" lead  ">Clients may withdraw consent for the processing of their personal information or request deletion, subject to legal and contractual obligations.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Changes to Privacy Policy</li>
            <ul>
            <li className=" lead  ">We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on our website, and clients will be notified accordingly.</li>
            {/* <li className=" lead  ">Use of Information</li> */}
            </ul>
          </ul>
          
          

          <p className="lead mb-4 text-center ">
          By engaging in our services, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
    
  )
}
