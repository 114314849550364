import React from 'react'

export default function Pp() {
  return (
    <div className='container'>
       <div className="px-4 py-5 my-5  cbg ">
        
        
        <h1 className="display-5 fw-bold text-body-emphasis txt-1 text-center ">Terms and Conditions</h1>
        <div className="col-lg-10 mx-auto">
        {/* <p className="lead mb-4 text-center ">
        By engaging in Numerology, Tarot or any services provided by us, you agree to abide by these Terms & Conditions.
          </p> */}
          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Service Agreement</li>
            <ul>
            {/* <li className=" lead  "></li> */}
            <li className=" lead  ">The information and guidance provided during consultations or workshops are based on personal beliefs, intuition, and spiritual understanding. They should be considered as guidance only.</li> 
          </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Client Responsibilities</li>
            <ul>
            <li className=" lead  ">Clients must provide accurate and truthful information required for the service, such as birth details, name, contact details etc.</li>
            <li className=" lead  ">Clients are responsible for making their own decisions based on the information and guidance received during consultations or workshops.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Professional Advice</li>
            <ul>
            <li className=" lead  ">All Divine practice such as Numerology, Tarot, etc, services are not a substitute for professional advice in areas such as finance, health, or legal matters.</li>
            <li className=" lead  ">Clients with serious mental health issues or undergoing significant life challenges are advised to seek the assistance of qualified professionals.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Confidentiality</li>
            <ul>
            <li className=" lead  ">Client information shared during consultations or workshops is treated with strict confidentiality.</li>
            <li className=" lead  ">We do not disclose client information to third parties without explicit consent, except as required by law.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Consent for Recording</li>
            <ul>
            <li className=" lead  ">Consultations or workshops may be recorded for quality assurance purposes. By participating, clients consent to the recording of sessions.</li>
            {/* <li className=" lead  ">Use of Information</li> */}
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Limitation of Liability</li>
            <ul>
            <li className=" lead  ">We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of our services.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Amendments to Terms</li>
            <ul>
            <li className=" lead  ">We reserves the right to modify or update these Terms & Conditions at any time. Clients will be notified of any changes.</li>
            {/* <li className=" lead  ">Use of Information</li> */}
            </ul>
          </ul>
          
          

          <p className="lead mb-4 text-center ">
          By engaging in our services, you acknowledge that you have read, understood, and agreed to these Terms & Conditions.
          </p>
        </div>
      </div>
    </div>
    
  )
}
