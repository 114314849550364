import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="container cbg3 theme1">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12  center-c txt-3">
                <img className="logo center-c " src="\Images\22.png"></img> <br />
                
              </div>

        {/* <a
          href="/"
          className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
        >
          <svg className="bi me-2" width="40" height="32">
            <use xlink:href="#bootstrap" />
          </svg>
        </a> */}

        {/* <ul className="nav col-md-4 justify-content-end">
          <li className="nav-item">
            <a href="#" className="nav-link px-2  ">
              Home
            </a>
          </li>
          
          <li className="nav-item">
            <a href="#" className="nav-link px-2 ">
            About
            </a>
          </li>
          <li className="nav-item">
            <a href="#" className="nav-link  px-2 ">
              Contact
            </a>
          </li>
        </ul> */}

        <div className="col-lg-4 col-md-4 col-sm-4 center-c ">
          <h5>QUICK LINKS</h5>
          <Link className="item-col my-2" reloadDocument to="/">
            HOME
          </Link>

          <Link className="item-col my-2" reloadDocument to="/Disclaimer">
            DISCLAIMER
          </Link>
          <Link className="item-col my-2" reloadDocument to="/Pp">
            PRIVACY POLICY
          </Link>
          <Link className="item-col my-2" reloadDocument to="/Tnc">
            TERMS & CONDITIONS
          </Link>
          <Link className="item-col my-2 mb-2" reloadDocument to="/Rr">
            RETURN & REFUND
          </Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 center-c" style={{ height: "auto" }}>
          <h5>CONTACT US </h5>
          <div className="bi bi-house my-2">
            &nbsp; Address:&nbsp;101, Shiv Sai Apartment,
            <br /> Above Sai Dham Hospital, Patil Wadi,
            <br /> Savarkar Nagar, Thane (w), India
          </div>
          <div className="bi bi-envelope my-2">
            &nbsp; Email:&nbsp; DIVINERDISCOVERY@GMAIL.COM{" "}
          </div>
          <div className="bi bi-phone my-2">
           
            &nbsp;Phone: &nbsp; +91-8850758136, +91-7400462017
          </div>
        </div>

        

        <div className="col-md-12 mt-2 center-c">
        <hr className=" hr-light" />
        &copy; 2024 Diviner Discoveries</div> 
      </footer>
    </div>
  );
}
