import logo from "./logo.svg";

/* The following line can be included in a src/App.scss */
/* The following line can be included in your src/index.js or App.js file */
import './App';
import Navbar from "./compo/Navbar";
import Footer from "./compo/Footer";
import About from "./compo/About";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Main from "./compo/Main";
import Contact from "./compo/Contact";
import Tarot from "./compo/Tarot";
import Nume from "./compo/Nume";
import Aka from "./compo/Aka";
import Tnc from "./compo/Tnc";
import Disclaimer from './compo/Disclaimer'
import Pp from './compo/Pp';
import Rr from './compo/Rr';
import TrDes from "./compo/TrDes";
import NumeDes from "./compo/NumeDes";
import AngDes from "./compo/AngDes";
import Angel from "./compo/Angel";
import Pay from "./compo/Pay";



 


function App() {
  return (
    <>

    
     <BrowserRouter>
     <Navbar />
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/About/" element={<About/>} />
        <Route path="/Contact/*" element={<Contact />} />
        <Route path="/Tarot/*" element={<Tarot />} />
        <Route path="/Nume/*" element={<Nume />} />
        <Route path="/Aka/*" element={<Aka />} />
        <Route path="/Tnc/*" element={<Tnc />} />
        <Route path="/Disclaimer/*" element={<Disclaimer/>} />
        <Route path="/Pp/*" element={<Pp />} />
        <Route path="/Rr/*" element={<Rr />} />
        <Route path="/TrDes/*" element={<TrDes />} />
        <Route path="/NumeDes/*" element={<NumeDes />} />
        <Route path="/AngDes/*" element={<AngDes />} />
        <Route path="/Angel/*" element={<Angel />} />
        <Route path="/Pay/"   element={<Pay  proAmount={1000} />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
    
   
    </>
  );
}

export default App;
