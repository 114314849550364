import React from "react";
export default function Section3() {
  
  return (
    <div>

<div className="sec2 cbg4">
        <div className="sec1_1 text-center wid-10 ">
        
        <h1 className="display-5 fw-bold  fcol cbg5 w-25 center-r ">ABOUT US</h1>
       
        </div>
      </div>


      <div className="px-4 py-5 my-5  cbg ">
        
        
        
        <div className="col-lg-10 mx-auto">
          <p className="lead mb-4 text-center ">
          Welcome to our sanctuary of cosmic guidance and spiritual insight, where the celestial realms converge with earthly wisdom to illuminate your path. At Diviner Discoveries, we specialize in astrology readings and spiritual services that empower individuals to navigate life’s journey with clarity and purpose.
          </p>
          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Our Mission</li>
            <ul>
            <li className=" lead  ">Our mission is rooted in the belief that the positions of the stars and planets at the moment of your birth hold profound insights into your character, strengths, challenges, and destiny. By harnessing the ancient wisdom of astrology and combining it with modern spiritual practices, we aim to help you unlock your true potential and achieve spiritual fulfillment.</li>
           
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">What We Offer</li>
            <ul>
            <li className=" lead  "> <div className="fw-bold">Personalized Astrology Readings: </div>
              Our experienced astrologers provide in-depth interpretations of your natal chart, offering valuable insights into your personality traits, career path, relationships, and more.</li>
            

              <li className=" lead  "> <div className="fw-bold">Tarot and Oracle Readings:  </div>
              Gain clarity and guidance through our tarot and oracle readings, which tap into the energies surrounding you to offer profound wisdom and advice.</li>

              <li className=" lead  "> <div className="fw-bold">Spiritual Counseling: </div>
              Whether you seek guidance on matters of love, career, or personal growth, our spiritual counselors offer compassionate support and practical advice rooted in spiritual principles.</li>

              <li className=" lead  "> <div className="fw-bold">Workshops and Classes:  </div>
              Deepen your understanding of astrology, tarot, meditation, and other spiritual practices through our workshops and classes designed for both beginners and advanced practitioners.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Our Approach</li>
            <ul>
            <li className=" lead  ">We believe in a holistic approach to spirituality, where mind, body, and spirit are interconnected. Our practitioners are dedicated to creating a supportive and nurturing environment where you can explore your spiritual journey without judgment.</li>
            
            </ul>
          </ul>

          {/* <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Meet Our Team</li>
            <ul>
            <li className=" lead  ">Our team consists of passionate astrologers, tarot readers, spiritual counselors, and educators who are committed to helping you uncover the wisdom and guidance that lie within. Each member brings a unique blend of expertise, intuition, and compassion to their practice, ensuring that you receive the highest quality of care and insight.</li>
           
            </ul>
          </ul> */}


          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Get Started</li>
            <ul>
            <li className=" lead  ">Whether you are seeking clarity on a specific issue, exploring your spiritual path, or simply curious about what the stars have to say, we are here to assist you. Embrace the transformative power of astrology and spiritual services and embark on a journey of self-discovery and empowerment today.</li>
           
            </ul>
          </ul>

          

          <p className="lead mb-4 text-center ">
          Discover the wisdom of the stars. Illuminate your path. Embrace your true potential. Welcome to 
          <div className="fw-bold">Diviner Discoveries</div>
          .
          </p>
        </div>
      </div>
       
    </div>
  );
}
