import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function TrDes() {
const amt = 1500;
const session = "Tarot Reading"

const nata = useNavigate()
  function clickk (){
    nata("/pay", {state : {amt : amt, session:session}})
  }

  return (
    <div className="container">
      <div className=" w-100  d-md-flex flex-md-row  my-3 ">
        <div className="col-md-6 px-2 py-2 cbg5">
          <img src="/Images/15.jpg" alt="Image" className="sec3-img1 "></img>
        </div>
        <div className="col-md-6 px-2 py-2  cbg5">
          <h1 className="display-5 fw-bold text-body-emphasis txt-1 text-center ">
            Tarot Reading
          </h1>
          <p className="lead mb-4 ">
            Ready to uncover the insights and guidance that await you? Book your
            tarot reading today and embark on a journey of self-discovery and
            empowerment.
          </p>
          <p className="lead mb-4 fw-bold d-md-flex  ">
            Only in:
            <i class="bi bi-currency-rupee fw-light">
              <s>2999</s>
            </i>
            &nbsp; &nbsp;
            <p class="bi bi-currency-rupee fs-5 ">1500</p>
          </p>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">Session Duration:</p>{" "}
            <p className="lead d-inline">1 Hour </p>
          </div>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">Session Mode:</p>{" "}
            <p className="lead d-inline">Online / Offline </p>
          </div>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline "> Category:</p>{" "}
            <p className="lead d-inline">Consultancy</p>
          </div>

          <a className="btn btn-primary my-3 "  onClick={clickk} >Book an Appointment</a>
        </div>
      </div>

      <div className=" w-100  d-md-flex flex-md-column  my-3 cbg5">
        <h3 className=" fw-bold text-body-emphasis txt-1 center-c py-3 ">
          Discover Clarity, Insight, and Guidance
        </h3>
        <div className="lead my-4 px-5">
          <p>
            Welcome to our Tarot Reading Section! Whether you're seeking answers
            to specific questions, guidance on life decisions, or simply a
            deeper understanding of your inner self, our tarot readings offer a
            unique and enlightening experience.
          </p>
          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">What to Expect</li>
            <p>
              During your tarot reading session, our experienced tarot reader
              will use the cards to explore your past, present, and potential
              future. The cards' rich symbolism will provide insights and
              perspectives to help you navigate life's challenges and
              opportunities.
            </p>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Types of Readings</li>
            <p>
              We offer a variety of tarot reading options to suit your needs:
            </p>
            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">General Reading:</p>
                <p>
                  {" "}
                  &nbsp; Gain a broad overview of your current life situation
                  and future possibilities.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Love and Relationships:</p>
                <p>
                  {" "}
                  &nbsp; Explore the dynamics of your relationships and gain
                  clarity on matters of the heart.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Career and Finances: </p>
                <p>
                  {" "}
                  &nbsp; Receive guidance on professional decisions and
                  financial matters.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Spiritual Guidance:</p>
                <p>
                  {" "}
                  &nbsp; Connect with your higher self and gain insights into
                  your spiritual path.
                </p>
              </li>
            </ul>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Booking Your Appointment</li>
            
            <ol class="list-group list-group-numbered">
              <li className="  list-group-item">
                <p className="fw-semibold d-inline">
                  Click on Appointment Button:
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Fill the Details and complete the payment process.
                </p>
              </li>

              {/* <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Fill details and make payment:</p>
                <p>
                  {" "}
                  &nbsp; Explore the dynamics of your relationships and gain
                  clarity on matters of the heart.
                </p>
              </li> */}

              <li className="list-group-item ">
                <p className="fw-semibold d-inline  ">
                  Get a call on your number:{" "}
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Our Team will call on your number to get your available
                  date, time and session type to schedule the session. Also you
                  can share any specific questions or topics you'd like to
                  explore during your session.
                </p>
              </li>

              <li className="  list-group-item">
                <p className="fw-semibold d-inline">Attend the Session:</p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Once all done, on the scheduled date & time join the
                  online session or visit our office (depending on the session
                  type).
                </p>
              </li>
            </ol>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">FAQs</li>
            <p className="fw-semibold">
              How long does a tarot reading session last?
            </p>
            <p>
              {" "}
              &nbsp; Our sessions typically last between 45 to 60 minutes,
              depending on the type of reading.
            </p>

            <p className="fw-semibold">
              Do I need to prepare anything for my reading?
            </p>
            <p>
              {" "}
              &nbsp; No preparation is needed, but you may want to think about
              any specific questions or areas of your life you would like to
              focus on.
            </p>

            <p className="fw-semibold">Can tarot predict the future?</p>
            <p>
              {" "}
              &nbsp; Tarot provides insights and guidance based on current
              energies and patterns. It can highlight potential future paths,
              but it is ultimately up to you to shape your destiny.
            </p>
          </ul>

          <a className="btn btn-primary my-3 "  onClick={clickk} >Book an Appointment</a>
        </div>
      </div>
    </div>
  );
}
