import React from "react";
import { useNavigate } from "react-router-dom";

export default function Tarot() {
  const amt = 24999;
  const session = "Tarot Course"
  
  const nata = useNavigate()
    function clickk (){
      nata("/pay", {state : {amt : amt, session:session}})
    }
  return (
    <div>
      {/* <div className="sec2 cbg6">
        <div className="sec1_1 text-center wid-10 ">
          <h1 className="display-5 fw-bold  fcol cbg5 w-25 center-r ">
            Tarot Course
          </h1>
        </div>
      </div> */}

      <div className=" w-100  d-md-flex flex-md-row  my-3 ">
        <div className="col-md-6 px-2 py-2 cbg5">
          <img src="/Images/11.jpeg" alt="Image" className="sec3-img1 "></img>
        </div>
        <div className="col-md-6 px-2 py-2  cbg5">
          <h3 className="fw-bold text-body-emphasis txt-1 text-center ">
            Master the Art of Tarot Reading
          </h3>
          <p className="lead mb-4 ">
            Unlock the mysteries of the tarot and develop your intuitive skills
            with our comprehensive tarot course. Whether you're a beginner or
            looking to deepen your practice, our course offers everything you
            need to become a confident and insightful tarot reader.
          </p>
          <p className="lead mb-4 fw-bold d-md-flex  ">
            Only in:
            <i class="bi bi-currency-rupee fw-light">
              <s>50000</s>
            </i>
            &nbsp; &nbsp;
            <p class="bi bi-currency-rupee fs-5 ">24999</p>
          </p>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">Class Duration:</p>{" "}
            <p className="lead d-inline">30 Hours </p>
          </div>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">Session Mode:</p>{" "}
            <p className="lead d-inline">Online / Offline </p>
          </div>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline "> Category:</p>{" "}
            <p className="lead d-inline">Course</p>
          </div>

          <a className="btn btn-primary my-3 "  onClick={clickk} >Enroll Now</a>
        </div>
      </div>

      <div className=" w-100  d-md-flex flex-md-column  my-3 cbg5">
        <h3 className=" fw-bold text-body-emphasis txt-1 center-c py-3 ">
          Course Overview
        </h3>
        <div className="lead my-4 px-5">
          <p>
            Our tarot course covers all aspects of tarot reading, from
            understanding the meanings of each card to developing your intuition
            and mastering various spreads. You'll learn how to use tarot as a
            tool for personal growth, guidance, and spiritual development.
          </p>
          {/* <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">What to Expect</li>
            <p>
              During your tarot reading session, our experienced tarot reader
              will use the cards to explore your past, present, and potential
              future. The cards' rich symbolism will provide insights and
              perspectives to help you navigate life's challenges and
              opportunities.
            </p>
          </ul> */}

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">What You Will Learn</li>
            {/* <p>
              We offer a variety of tarot reading options to suit your needs:
            </p> */}
            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Introduction to Tarot: </p>
                <p>
                  {" "}
                  &nbsp; Discover the history and structure of the tarot deck.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Card Meanings:</p>
                <p>
                  {" "}
                  &nbsp; Learn the meanings of the Major and Minor Arcana cards.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Intuitive Reading: </p>
                <p>
                  {" "}
                  &nbsp;Develop your intuition and learn how to interpret the
                  cards beyond traditional meanings.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Tarot Spreads:</p>
                <p>
                  {" "}
                  &nbsp; Master different tarot spreads for various questions
                  and situations.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Ethical Reading Practices:</p>
                <p>
                  {" "}
                  &nbsp; Understand the ethics of tarot reading and how to offer
                  supportive and constructive guidance.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Practice Session:</p>
                <p>
                  {" "}
                  &nbsp; Explore how to use tarot for yourself and others in
                  real-life situations.
                </p>
              </li>
            </ul>
          </ul>


          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Course Features</li>
            {/* <p>
              We offer a variety of tarot reading options to suit your needs:
            </p> */}
            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Comprehensive Lessons:  </p>
                <p>
                  {" "}
                  &nbsp;Detailed onlin / offline classes and written materials.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Interactive Exercises: </p>
                <p>
                  {" "}
                  &nbsp; Hands-on exercises to practice your skills.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Guided Meditations:  </p>
                <p>
                  {" "}
                  &nbsp;Meditations to enhance your intuitive abilities.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Lifetime Support: </p>
                <p>
                  {" "}
                  &nbsp; Lifetime support for any challenges, doubts or huddels.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Certificate of Completion:</p>
                <p>
                  {" "}
                  &nbsp; Receive a certificate upon completing the course.
                </p>
              </li>
            </ul>

            
          </ul>


          {/* <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Course Modules</li>
            <p>
              We offer a variety of Tarot reading options to suit your needs:
            </p>
            <ol class="list-group list-group-numbered">
              <li className="  list-group-item">
                <p className="fw-semibold d-inline">
                Introduction to Tarot
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Fill the Details and complete the payment process.
                </p>
              </li>

              
              
            </ol>
          </ul> */}

          {/* <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">FAQs</li>
            <p className="fw-semibold">
              How long does a tarot reading session last?
            </p>
            <p>
              {" "}
              &nbsp; Our sessions typically last between 45 to 60 minutes,
              depending on the type of reading.
            </p>

            <p className="fw-semibold">
              Do I need to prepare anything for my reading?
            </p>
            <p>
              {" "}
              &nbsp; No preparation is needed, but you may want to think about
              any specific questions or areas of your life you would like to
              focus on.
            </p>

            <p className="fw-semibold">Can tarot predict the future?</p>
            <p>
              {" "}
              &nbsp; Tarot provides insights and guidance based on current
              energies and patterns. It can highlight potential future paths,
              but it is ultimately up to you to shape your destiny.
            </p>
          </ul> */}



<ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Enrollment in this course</li>
            
            <ol class="list-group list-group-numbered">
              <li className="  list-group-item">
                <p className="fw-semibold d-inline">
                  Click on Enroll Now Button:
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Fill the Details and complete the payment process.
                </p>
              </li>

              {/* <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Fill details and make payment:</p>
                <p>
                  {" "}
                  &nbsp; Explore the dynamics of your relationships and gain
                  clarity on matters of the heart.
                </p>
              </li> */}

              <li className="list-group-item ">
                <p className="fw-semibold d-inline  ">
                  Get a call on your number:{" "}
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Our Team will call on your number to get your available
                  date, time and class mode (onlin / offline) to schedule the class. Also you
                  can ask any questions or topics you'd like to
                  explore during your class.
                </p>
              </li>

              <li className="  list-group-item">
                <p className="fw-semibold d-inline">Attend the class:</p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Once all done, on the scheduled date & time join the
                  online class or visit our office (depending on the class
                  type).
                </p>
              </li>
            </ol>
          </ul>


<p className="lead">
Ready to embark on your tarot journey? Enroll in our tarot course today and start mastering the art of tarot reading!
          </p>
          <a className="btn btn-primary my-3 "  onClick={clickk} >Enroll Now</a>
        </div>
      </div>
    </div>
  );
}
