import React from 'react'
export default function Section1() {
  return (
    <div>
       <div className="px-4 py-5 my-5 text-center cbg5 ">
        
        
        <h1 className="display-5 fw-bold text-body-emphasis txt-1 ">OUR GOAL</h1>
        <div className="col-lg-10 mx-auto">
          <p className="lead mb-4 ">
          This is the process of uncovering hidden or unknown information or insights through divination practices. Divination is the practice of seeking knowledge or guidance through supernatural or mystical means.
           It is associated with ancient systems and involves various methods such as Tarot card, Numerology, Crystals and Energies.
          </p>
          {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-4 gap-3">
              Primary button
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-lg px-4"
            >
              Secondary
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}
