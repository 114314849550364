import React from 'react'

export default function Banner() {
  return (
    <div>
       <div className="sec1 cbg1">
        <div className="sec1_1 text-center wid-10 ">
        
        <h1 className="display-5 fw-bold  fcol ">THE JOURNEY OF SELF DISCOVERY</h1>
        <div className="col-lg-8 mx-auto ">
          <p className="lead mb-4 txt-2 ">
          Experience the power of intuitive guidance and unlock your full potential
          </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            {/* <button type="button" className="btn btn-primary btn-lg px-4 gap-3">
              BOOK APPOINTMENT
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-lg px-4"
            >
              Secondary
            </button> */}

            <a className='bi bi-whatsapp  btn btn-primary  btn-lg ' href='https://wa.me/+918850758136'>&nbsp;
            BOOK APPOINTMENT TODAY
            </a>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}
