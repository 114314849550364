import React from "react";
import { useNavigate } from "react-router-dom";


export default function TrDes() {
  const amt = 1500;
const session = "Numerology Reading"

const nata = useNavigate()
  function clickk (){
    nata("/pay", {state : {amt : amt, session:session}})
  }
  return (
    <div className="container">
      <div className=" w-100  d-md-flex flex-md-row  my-3 ">
        <div className="col-md-6 px-2 py-2 cbg5">
          <img src="/Images/img (16).jpeg" alt="Image" className="sec3-img1 "></img>
        </div>
        <div className="col-md-6 px-2 py-2  cbg5">
          <h1 className="display-5 fw-bold text-body-emphasis txt-1 text-center ">
            Numerology Reading
          </h1>
          <p className="lead mb-4 ">
            Ready to uncover the wisdom of your numbers? Book your numerology
            reading today and embark on a journey of self-discovery and
            empowerment.
          </p>
          <p className="lead mb-4 fw-bold d-md-flex  ">
            Only in:
            <i class="bi bi-currency-rupee fw-light">
              <s>2999</s>
            </i>
            &nbsp; &nbsp;
            <p class="bi bi-currency-rupee fs-5 ">1500</p>
          </p>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">Session Duration:</p>{" "}
            <p className="lead d-inline">1 Hour </p>
          </div>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline "> Mode:</p>{" "}
            <p className="lead d-inline">Online / Offline </p>
          </div>

          <div className="d-block">
            <p className="lead mb-4 fw-bold d-inline ">Session Category:</p>{" "}
            <p className="lead d-inline">Consultancy</p>
          </div>

          <a className="btn btn-primary my-3 "  onClick={clickk} >Get an Appointment</a>
        </div>
      </div>

      <div className=" w-100  d-md-flex flex-md-column  my-3 cbg5">
        <h3 className=" fw-bold text-body-emphasis txt-1 center-c py-3 ">
          Unlock the Secrets of Your Numbers
        </h3>
        <div className="lead my-4 px-5">
          <p>
            Welcome to our Numerology Reading Section! Discover the profound
            insights that numbers can offer about your life path, personality,
            and destiny. Our numerology readings provide clarity and guidance,
            helping you make informed decisions and better understand yourself
            and your life's journey.
          </p>
          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">What to Expect</li>
            <p>
              In your numerology reading session, our experienced numerologist
              will analyze significant numbers related to your birth date and
              name. These numbers reveal patterns and influences that shape your
              experiences and future possibilities.
            </p>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Types of Readings</li>
            <p>
              We offer a variety of Numerology reading options to suit your
              needs:
            </p>
            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Life Path Reading:</p>
                <p>
                  {" "}
                  &nbsp; Understand your life purpose and the key lessons you're
                  here to learn.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Personality Profile:</p>
                <p>
                  {" "}
                  &nbsp; Gain insights into your strengths, challenges, and
                  unique traits.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Compatibility Reading: </p>
                <p>
                  {" "}
                  &nbsp; Explore the dynamics of your relationships through the
                  lens of numerology.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Love and Relationships:</p>
                <p>
                  {" "}
                  &nbsp; Explore the dynamics of your relationships and gain
                  clarity on matters of the heart.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Career and Finances: </p>
                <p>
                  {" "}
                  &nbsp; Receive guidance on professional decisions and
                  financial matters.
                </p>
              </li>
            </ul>

            <ul>
              <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Yearly / Monthly Forecast:</p>
                <p>
                  {" "}
                  &nbsp; Discover what the coming year / month holds for you and
                  how to best navigate its energies.
                </p>
              </li>
            </ul>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">Booking Your Appointment</li>
            <p>
              We offer a variety of Numerology reading options to suit your needs:
            </p>
            <ol class="list-group list-group-numbered">
              <li className="  list-group-item">
                <p className="fw-semibold d-inline">
                  Click on Appointment Button:
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Fill the Details and complete the payment process.
                </p>
              </li>

              {/* <li className=" d-md-flex flex-md-row">
                <p className="fw-semibold">Fill details and make payment:</p>
                <p>
                  {" "}
                  &nbsp; Explore the dynamics of your relationships and gain
                  clarity on matters of the heart.
                </p>
              </li> */}

              <li className="list-group-item ">
                <p className="fw-semibold d-inline  ">
                  Get a call on your number:{" "}
                </p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Our Team will call on your number to get your available
                  date, time and session type to schedule the session. Also you
                  can share any specific questions or topics you'd like to
                  explore during your session.
                </p>
              </li>

              <li className="  list-group-item">
                <p className="fw-semibold d-inline">Attend the Session:</p>
                <p className="d-inline">
                  {" "}
                  &nbsp; Once all done, on the scheduled date & time join the
                  online session or visit our office (depending on the session
                  type).
                </p>
              </li>
            </ol>
          </ul>

          <ul className=" list-unstyled my-5 ">
            <li className=" lead fw-bold my-2">FAQs</li>
            <p className="fw-semibold">
              How long does a numerology reading session last?
            </p>
            <p>
              {" "}
              &nbsp; Our sessions typically last between 45 to 60 minutes,
              depending on the type of reading.
            </p>

            <p className="fw-semibold">
              Do I need to prepare anything for my reading?
            </p>
            <p>
              {" "}
              &nbsp; No preparation is needed, but you may want to think about
              any specific questions or areas of your life you would like to
              focus on.
            </p>

            <p className="fw-semibold">Can numerology predict the future?</p>
            <p>
              {" "}
              &nbsp; Numerology provides insights into patterns and tendencies
              that can influence your future. It offers guidance and awareness
              to help you make informed choices.
            </p>
          </ul>

          <a className="btn btn-primary my-3 "  onClick={clickk} >Get an Appointment</a>
        </div>
      </div>
    </div>
  );
}
