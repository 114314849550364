import React from "react";

export default function Section5() {
  return (
    <div className="cbg2">
      <h1 className="display-5 fw-bold text-body-emphasis text-center mar-b-30 txt-2 pad-t ">
        YOU CAN ALSO LEARN
      </h1>

      <div className="container ">
        <div className="row center-r">
          <div className="col-lg-4  ">
            <img src="/Images/12.jpg" className=" sec5-img1"></img>
            <h2 className="fw-normal">Tarot Reading</h2>
            <p>
              <a className="btn btn-secondary" href="/Tarot">
                View details &raquo;
              </a>
            </p>
          </div>

          <div className="col-lg-4 ">
            <img src="/Images/img (16).jpeg" className=" sec5-img1"></img>
            <h2 className="fw-normal">Numerology</h2>
            <p>
              <a className="btn btn-secondary" href="/Nume">
                View details &raquo;
              </a>
            </p>
          </div>

          {/* <div className="col-lg-4 ">
            <img src="/Images/img (12).jpeg" className=" sec5-img1"></img>
            <h2 className="fw-normal">Crystal Healing</h2>
            <p>
              <a className="btn btn-secondary" href="#">
                View details &raquo;
              </a>
            </p>
          </div> */}

          {/* <div className="col-lg-4 ">
            <img src="/Images/img (5).jpeg" className=" sec5-img1"></img>
            <h2 className="fw-normal">Reiki</h2>
            <p>
              <a className="btn btn-secondary" href="#">
                View details &raquo;
              </a>
            </p>
          </div> */}

          <div className="col-lg-4 ">
            <img src="/Images/img (6).jpeg" className=" sec5-img1"></img>
            <h2 className="fw-normal">Angel Healing</h2>
            <p>
              <a className="btn btn-secondary" href="Angel">
                View details &raquo;
              </a>
            </p>
          </div>

          {/* <div className="col-lg-4 ">
            <img src="/Images/img (3).jpeg" className=" sec5-img1"></img>
            <h2 className="fw-normal">Akashik Record </h2>
            <p>
              <a className="btn btn-secondary" href="#">
                View details &raquo;
              </a>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
