import React from "react";
// import imgg1 from "../DD Images/22.png";

import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg theme1 ">
      <div className="container-fluid ">
        <a href="/">
          <img src="/Images/22.png" className="logo" />{" "}
        </a>
        <button
          className="navbar-toggler btn "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className=" bi bi-list"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link  reloadDocument className="nav-link active " aria-current="page" to="/">
                HOME
              </Link>
            </li>

            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                READINGS
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link className="dropdown-item" aria-current="page" to="/Tarot">
                TAROT
              </Link>
              </li> <li>
                <Link className="dropdown-item" aria-current="page" to="/Nume">
                NUMEROLOGY
              </Link>
              </li> <li>
                <Link className="dropdown-item" aria-current="page" to="/Aka">
                AKASHIK RECORDS
              </Link>
              </li>
              </ul>
            </li> */}

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                COURSES
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link className="dropdown-item" aria-current="page" reloadDocument to="/Tarot">
                TAROT
              </Link>
              </li> <li>
                <Link className="dropdown-item" aria-current="page" reloadDocument to="/Nume">
                NUMEROLOGY
              </Link>
              </li> <li>
                <Link className="dropdown-item" aria-current="page" reloadDocument to="Angel">
               ANGEL HEALING
              </Link>
              </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link reloadDocument
                className="nav-link active"
                aria-current="page"
                to="/About"
              >
                ABOUT US
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
