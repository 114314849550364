import React from 'react'

export default function Disclaimer() {
  return (
    <div>
    <div className="px-4 py-5 my-5 text-center cbg ">
     
     
     <h1 className="display-5 fw-bold text-body-emphasis txt-1 ">Disclaimer</h1>
     <div className="col-lg-10 mx-auto">
       <p className="lead mb-4 ">
       The interpretations, advice and recommendations provided during consultations are based on personal beliefs, intuition, and spiritual understanding. Results may vary, and we make no guarantees regarding the accuracy or effectiveness of the information shared.
       </p>
       <p className="lead mb-4 ">
       Clients are encouraged to use their discretion and judgment when applying the guidance received from our services. We do not take responsibility for any decisions made or actions taken as a result of the consultations. Furthermore, individuals with serious mental health issues or those undergoing significant life challenges are advised to seek the assistance of qualified professionals such as therapists, counselors, or medical practitioners.
       </p>
       <p className="lead mb-4 ">
       By engaging in our any services, you acknowledge and accept the terms of this disclaimer.
       </p>







       {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
         <button type="button" className="btn btn-primary btn-lg px-4 gap-3">
           Primary button
         </button>
         <button
           type="button"
           className="btn btn-outline-secondary btn-lg px-4"
         >
           Secondary
         </button>
       </div> */}
     </div>
   </div>
 </div>
  )
}
