import React from 'react'

export default function Pp() {
  return (
    <div className='container'>
       <div className="px-4 py-5 my-5  cbg ">
        
        
        <h1 className="display-5 fw-bold text-body-emphasis txt-1 text-center ">Return and Refund Policy</h1>
        <div className="col-lg-10 mx-auto">
        {/* <p className="lead mb-4 text-center ">
        By engaging in Numerology, Tarot or any services provided by us, you agree to abide by these Return & refund Policies.
          </p> */}
          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Service Fees</li>
            <ul>
            {/* <li className=" lead  "></li> */}
            <li className=" lead  ">All fees for Numerology, Tarot or any services are non-refundable once payment has been made.</li>
             
          </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Reschedule / Cancellation Policy</li>
            <ul>
            <li className=" lead  ">Clients may reschedule appointments for services up to 48 hours before the scheduled session without incurring any cancellation fees..</li>
            <li className=" lead  ">Cancellations made within 48 hour of the scheduled session will not be eligible for a refund.</li>
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">No-Show Policy</li>
            <ul>
            <li className=" lead  ">No-shows for scheduled sessions will result in forfeiture of the service fee, and no refund will be issued.</li>
            
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Unsatisfactory Service</li>
            <ul>
            <li className=" lead  ">If a client is unsatisfied with the quality of the service provided, they may request a rescheduled session, but refunds will not be issued.</li>
            
            </ul>
          </ul>

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Payment Disputes</li>
            <ul>
            <li className=" lead  ">Clients who wish to dispute a charge should contact us directly to resolve the issue. You can write us on divinerdiscoveries@gmail.com</li>
            {/* <li className=" lead  ">Use of Information</li> */}
            </ul>
          </ul>

        

          <ul className=' list-unstyled '>
            <li className=" lead fw-bold">Changes to Refund Policy</li>
            <ul>
            <li className=" lead  ">We  reserves the right to modify or update this Refund Policy at any time. Clients will be notified of any changes.</li>
            {/* <li className=" lead  ">Use of Information</li> */}
            </ul>
          </ul>
          
          

          <p className="lead mb-4 text-center ">
          By engaging in our services, you acknowledge that you have read, understood, and agreed to this No Refund Policy.
          </p>
        </div>
      </div>
    </div>
    
  )
}
